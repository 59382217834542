@keyframes bubble {
    from { opacity: .92; }
    to{ opacity: 1; }
}

@keyframes fadeIn {
    0% {
        opacity: .9;
        transform: translateY(.1rem);
    }
    70% {
        opacity: .95;
        transform: translateY(-.1rem);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

.Palette-Box {
    flex: 0 0 25rem;
    height: 29rem;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1.5rem;
    background: var(--color-white-l);
    border-radius: .8rem;
    animation: fadeIn 1s ease-in-out;
}

@media (max-width: 425px) {
    .Palette-Box {
        flex: 0 0 15rem;
        height: 19rem;
        padding: 1rem;
        margin: .5rem;
    }
}

.Palette {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.C1,
.C2,
.C3,
.C4 {
    position: relative;
    cursor: pointer;
    animation: bubble 1.5s ease-in;
}

.C1 {
    flex: 3;
    animation-delay: .4s;
}

.C2 {
    flex: 2;
    animation-delay: .3s;
}

.C3 {
    flex: 1.5;
    animation-delay: .2s;
}

.C4 {
    flex: 1;
    animation-delay: .1s;
}

.C__Code {
    position: absolute;
    opacity: 0;
    bottom: 0;
    font-size: 1.5rem;
    padding: .2rem .5rem;
    color: var(--color-white-l);
    background: var(--color-black-lt);
    transition: .1s ease-in-out;
}

@media (max-width: 425px) {
    .C__Code {
        font-size: 1.2rem;
    }
}

.C__Code::selection {
    background: none;
}

.C1:hover .C__Code,
.C2:hover .C__Code,
.C3:hover .C__Code,
.C4:hover .C__Code {
    opacity: 1;
}

.Controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Save {
    font-size: 1.5rem;
    padding: .5rem 1rem;
    border-radius: .5rem;
    cursor: pointer;
    border: .1rem solid var(--color-grey-l);
    transition: .1s ease-in-out;
}

.Save:hover {
    background: var(--color-blue-l);
}

.Save::selection {
    background: none;
}

.Age {
    color: var(--color-grey-d);
    font-size: 1.2rem;
}