.Saved {
    padding: 7rem 2rem 2rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100vh;
    position: relative;
}

@media (max-width: 425px) {
    .Saved {
        padding: 7rem 0 2rem 0;
    }
}