.Empty {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Empty__Img {
    height: 100%;
    margin-top: -5rem;
}

@media (max-width: 580px) {
    .Empty__Img {
        height: 60%;
    }
}

.Empty__Img::selection {
    background: none;
}

.Empty__Text {
    font-size: 2rem;
    margin-bottom: 2rem;
}

@media (max-width: 425px) {
    .Empty__Text {
        font-size: 2rem;
        text-align: center;
    }
}

.Empty__Btn {
    display: inline-block;
    color: initial;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    border: .1rem solid var(--color-grey-l);
    border-radius: .4rem;
    margin-bottom: 5rem;
    transition: .1s ease-in-out;
}

.Empty__Btn:hover {
    background: var(--color-blue-l);
}