.Navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    padding: 0 2rem;
    background: var(--color-white-l);
    z-index: 100;
}

.Link {
    color: inherit;
    text-decoration: none;
}

.Link:not(:last-child) {
    margin-right: 1.5rem;
}

.Logo-Box {
    display: flex;
    align-items: center;
}

.Logo-Box__Logo {
    margin-right: 1rem;
}

.Logo-Box__Logo {
    width: 3rem;
    border-radius: 50%;
}

.Logo-Box__Text {
    font-size: 1.8rem;
    margin-bottom: .1rem;
}

.Navbar__List {
    display: flex;
}