:root {
  --color-bg: #fafbfb;

  --color-white-l: #fff;

  --color-black-lt: rgba(0, 0, 0, 0.267);

  --color-grey-l: #c8c8c88c;
  --color-grey-d: #8b8b8b;

  --color-blue-l: #f5fcff;
}

/*utility classes*/
.Shadow-Light {
  box-shadow: 0 .5rem 1rem 0 rgba(0,64,128,.05);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
